addScriptToQueue(() => {
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', function () {
            // Don't fire if already fired.
            if (window.resourcesAndDOMReady !== true) {
                document.dispatchEvent(new Event('resourcesAndDOMReady'));
                window.resourcesAndDOMReady = true;
            }
        });
    } else {
        // Don't fire if already fired.
        if (window.resourcesAndDOMReady !== true) {
            document.dispatchEvent(new Event('resourcesAndDOMReady'));
            window.resourcesAndDOMReady = true;
        }
    }
}, 0);